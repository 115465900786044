.tracking-container {
  margin: 10px;
  .tracking-header {
    display: flex;
    flex: 1;
    justify-content: space-between;
    font-family: Poppins-Bold;
    margin: 5px;
  }
  .tracking-info {
    font-family: Poppins-Medium;
    color: #141c36;
  }
  .tracking-date {
    color: #707070;
  }
  .tracking-status {
    margin-right: 5px;
  }
  .link {
    cursor: pointer;
    text-decoration: none;
    color: #24abfb;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
