.app {
  display: flex;
  flex: 1 1 auto;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.side-bar {
  flex: 1 1 auto;
  height: 100%;
}

.toolbar {
  min-height: 56px;
}

@media (min-width: 0px) and (orientation: landscape) {
  .toolbar {
    min-height: 48px;
  }
}

@media (min-width: 600px) {
  .toolbar {
    min-height: 64px;
  }
}

.main {
  display: grid;
  grid-template-rows: auto 1fr;
  flex: 1;
}

.main-content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.page {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin: 20px;
}
