.edit-profile-container {
  display: grid;
  grid-template-rows: 1fr auto;
  flex: 1;
}
.edit-profile-form-stepper {
  margin-top: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  .stepper {
    flex: 1;
  }
  .stepper-actions {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
}
