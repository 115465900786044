
.eligible {
    margin-left: 15px;
    background-color: green;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
  }
  .notEligible {
    margin-left: 15px;
    background-color: red;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
  }