.thanks-screen {
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  .thanks-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    background: #fff;
    padding: 30px 50px;
    border-radius: 3px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    @media only screen and (max-width: 640px) {
      width: 80%;
      background: none;
      box-shadow: none;
      border-radius: 0px;
    }
    div {
      width: 100%;
    }
  }
  .sub-message {
    margin-bottom: 20px;
  }
  .close-btn-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
}
