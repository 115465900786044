.duplicate-order-form {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  // overflow: auto;
  .question-header {
    color: rgba(0, 0, 0, 0.94);
    padding: 5px;
    font-size: 1.15rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    margin-top: 5px;
  }
  .question-answer {
    font-size: 14px;
    padding-left: 10px;
    font-weight: 400;
    font-family: Arial;
  }
  fieldset legend {
    background: none;
    font-weight: 700;
    margin: 0;
  }

  fieldset {
    border: 1px solid #ddd;
    margin: 1.125rem 10px;
  }
  legend {
    font-weight: 700;
    margin: 0;
    padding: 0 0.1875rem;
  }

  .duplicate-order-container {
    display: flex;
    flex: 1;
    margin-top: 70px;
    height: 100%;
    overflow: auto;
    flex-direction: column;
    padding: 0px 20px;
  }

  .dysfunctions-container {
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    justify-content: center;
  }
}

@media only screen and (max-width: 640px) {
  .duplicate-order-container {
    padding: 0px !important;
  }

  fieldset {
    margin: 1rem 5px !important;
  }

  .dysfunctions-container {
    padding: 5px;
  }
}
