.knowledge-base {
  h1,
  h2,
  h3,
  h4 {
    color: unset;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 1.125rem;
  }
  h3 {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 0.9375rem;
    padding-bottom: 0.3125rem;
    font-size: 1.375rem;
  }
  p {
    font-family: inherit;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.6;
    margin-bottom: 1.875rem;
    text-rendering: optimizeLegibility;
  }
}
