.document-title {
  font-size: 1.25rem;
  font-family: Poppins-Bold;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  padding: 0px 5px;
  display: flex;
  flex: 1;
  align-items: center;
  .document-title-text {
    display: flex;
    flex: 1;
  }
}

.document-description {
  font-family: "Poppins-Regular";
  padding: 5px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  word-wrap: wrap;
  .link {
    cursor: pointer;
    text-decoration: none;
    color: #24abfb;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.document-footer {
  margin: 10px;
  color: #141c36b3;
  display: flex;
  .divider {
    padding: 0 10px;
  }
  .document-datetime {
    color: #141c36b3;
    margin-left: 4px;
    font-family: Poppins-Regular;
  }
}
