.calendar-and-timeslots-container {
  display: flex;
  gap: 5%;
  padding: 10px;

  .schedules-list-wrapper {
    height: 300px;
    width: 100%;
    border: 1px solid #ccc;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .schedules-list-container {
      flex: 1;
      overflow-y: auto;
      padding: 4px;
    }
    .spinner-container {
      height: 295px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .coach-timeslots-container {
      padding: 0 10px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    }
  }
}

@media only screen and (max-width: 1150px) {
  .calendar-and-timeslots-container {
    flex-direction: column;
    padding: 5px;
  }
  .calendar-container {
    width: 100%;
    transform: translateX(-1em);
    margin-bottom: 2em;
  }

  .coach-timeslots-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}
@media only screen and (max-width: 500px) {
  .coach-timeslots-container {
    padding: 0;
  }
  .calendar-and-timeslots-container {
    padding: 0;
    margin: 0;
    gap: 0;
  }
 
  .MuiPickersLayout-contentWrapper {
    grid-column: 1;
  }
}
