$sidebarWidth: 240px;

.side-bar {
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  background-color: #24abfb;
  background-image: url("../../assets/images/bar_patten.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top;
  overflow-x: hidden;
}

.drawerPaper {
  width: $sidebarWidth;
}

.side-bar-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.logout-container {
  border-top: 1px solid #fff;
  width: 100%;
}

.side-bar-item {
  width: 100%;
  color: #ffffff;
  padding: 10px;
  margin: 0px 5px;
  font-family: Poppins-Medium;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  img {
    margin-right: 15px;
  }
}
.user-welcome {
  color: white;
  flex-direction: column;
  margin: 5px;
  align-items: center;
  justify-self: center;
  padding: 10px;
  .welcome-container {
    margin-top: 15px;
    font-size: 22px;
    .welcome-label {
      margin-bottom: 10px;
    }
  }
}
