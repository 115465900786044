.dashboard-container {
  padding-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 50px;
  grid-auto-rows: 120px;
  grid-gap: 20px;
  justify-items: center;

  @media only screen and (max-width: 640px) {
    grid-template-columns: 1fr 1fr;
    padding: 15px;
    grid-gap: 15px;
  }
}

.dashboard-card {
  display: flex;
  flex: 1;
  width: 100%;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  font-family: Poppins-Bold;
  color: #141c36;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16), 0 2px 3px rgba(0, 0, 0, 0.23);
  &:hover {
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .dashboard-label {
    font-size: 16px;
    padding-bottom: 5px;
  }
  .dashboard-sub-label {
    font-size: 12px;
    color: #141c36;
    font-family: Poppins-Regular;
  }
  .material-icons.MuiIcon-root {
    color: white;
    fill: white;
  }
}

.dashboard-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.layout-full {
  height: 100%;
  grid-column: auto / span 4;

  @media (max-width: 600px) {
    grid-column: auto / span 2;
  }
  display: inline-flex;
  flex: 1;
  width: 100%;

  .dashboard-layout {
    display: grid;
    grid-template-columns: 1fr auto;
    flex: 1;
    height: 100%;
    width: 100%;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      width: 100%;
      img {
        display: flex;
        align-self: center;
        justify-self: center;
        width: 60px;
      }
    }
    > img {
      align-self: flex-end;
      height: 120px;
    }
  }
}

.layout-full-left {
  height: 100%;
  grid-column: auto / span 2;
  padding: 0px 15px;
  .dashboard-layout {
    display: grid;
    grid-template-columns: auto 1fr auto;
    flex: 1;
    height: 100%;
    align-items: center;
    img:nth-child(1) {
      width: 60px;
      margin-left: 50px;
    }
    img:nth-child(2) {
      align-self: flex-end;
      height: 18px;
      margin-right: 100px;
    }
  }
}

.layout-full-right {
  grid-column: auto / span 2;
  padding: 0px 15px;

  .dashboard-layout {
    display: grid;
    grid-template-columns: 1fr auto;
    flex: 1;
    height: 100%;
    align-items: center;
    img {
      width: 60px;
      margin-right: 50px;
    }
  }
}

.layout-half {
  .dashboard-layout {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 50px;
      height: 50px;
    }
  }
}

@media (max-width: 600px) {
  .dashboard-label {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
  }
}

.recall-alert-banner {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
}

.text-consent {
  padding: 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-self: center;
  font-size: 20px;
  @media only screen and (max-width: 640px) {
    width: 100%;
    background: none;
    box-shadow: none;
    border-radius: 0px;
  }
}

.text-consent-form-container{
    display: flex;
    flex-direction: column;
    padding-left: 60px;
    padding-right: 60px;
    align-items: center;
}

.inactive-message-container {
    padding: 50px;
}