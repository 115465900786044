.orders-container {
  margin: 10px;
  .orders-header {
    display: flex;
    flex: 1;
    justify-content: space-between;
    font-family: Poppins-Bold;
    margin: 5px;
  }
  .order-info {
    font-family: Poppins-Medium;
    color: #141c36;
  }
  .order-date {
    color: #707070;
  }
  .order-status {
    margin-right: 5px;
  }
}

.order-details-container {
  div {
    font-size: 18px;
  }
  .order-detail-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
