.product-card {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  cursor: pointer;
  .product-img {
    width: 72px;
    height: 72px;
  }
  .product-name {
    color: #141c36;
    font-size: 14px;
    font-family: Poppins-Bold;
  }
  .product-manufacturer {
    font-family: Poppins-Regular;
    margin-top: 3px;
    font-size: 12px;
    color: #24abfb;
  }
  .product-purchase-date {
    font-size: 12px;
    color: #707070;
    font-family: Poppins-Regular;
  }
}
