.chat-window {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  border: 3px solid #f1f1f1;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  flex: 1;
  flex-direction: column;
  z-index: 9;
  .chat-window-container {
    max-width: 100vw;
    width: 300px;
    height: 100%;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    background-color: white;

    .chat-window-header {
      display: flex;
      flex: 1;
      border-bottom: 1px solid #ccc;
      z-index: 5000;
      padding: 5px;
      align-items: center;
      color: #141c36;
      font-size: 16px;
      max-height: 60px;
      font-family: Poppins-Bold;
      > span {
        display: flex;
        flex: 1;
        padding-left: 10px;
      }
    }
    .chat-window-content {
      min-height: 200px;
      width: 100%;
      min-width: 280px;
      height: 365px;
      display: flex;
      flex: 1;
      flex-direction: column;
      font-size: 16px;
      display: grid;
      grid-template-rows: 1fr auto;
      overflow: hidden;
      flex: 1 1;
    }
  }
}

.messaging-fab {
  transform: scale(1);
  &.alert {
    animation: pulse 2s infinite;
    border: 1px solid rgba(0, 255, 0, 0.5);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@media (max-width: 600px) {
  .chat-window {
    position: absolute !important;
    z-index: 1300;
  }
  .chat-window,
  .chat-window-container {
    top: 0;
    height: 100%;
    margin: 0px 2px;
    margin-right: 5px;
    width: 100%;
    min-width: 100vw;
  }
}

.notify {
  content: "";
  position: absolute;
  top: 0px;
  right: -3px;
  font-size: 18px;
  font-weight: 700;
  color: white;
  z-index: 1;
  opacity: 0;
  line-height: 100%;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background: linear-gradient(#1aff1a, #00f800, green);
  transition: opacity 0.1s ease-out;
  animation: notification-alert 0.9s ease-out infinite alternate;
}

.notify.alert {
  opacity: 1;
}

@keyframes notification-alert {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}

.messages-container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow: auto;
  height: 100%;
  min-height: min-content; /* needs vendor prefixes */
  background: #ddd;
}

.my-msg {
  .msg-details {
    color: #888;
  }
  .msg-author {
    font-weight: bold;
  }
}
.participant-msg {
  .msg-details {
    color: #0085d1;
  }
}
.my-msg,
.participant-msg {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  .msg-details {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 5px;
  }
  .msg-body {
    padding: 5px;
    display: inline-block;
    overflow-wrap: anywhere;
    color: #141c36;
  }
}
.message.log {
  color: #bbb;
  align-items: center;
  .msg-body {
    border-radius: 0;
    margin-top: 5px;
  }
}

.message.my-msg {
  align-items: flex-start;
  margin-left: 8px;
  .msg-body {
    background-color: rgba(245, 245, 245, 1);
    margin-top: 0px;
    margin-bottom: 8px;
    margin-right: 18px;
    margin-left: 10px;
    flex: 1;
    align-self: flex-start;
    border-bottom-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}
.message.participant-msg {
  align-items: flex-end;
  margin-right: 5px;

  .msg-body {
    background-color: rgba(37, 150, 216, 0.1);
    margin-top: 0px;
    margin-bottom: 8px;
    margin-right: 10px;
    margin-left: 18px;
    flex: 1;
    align-self: flex-end;

    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}
.message {
  margin-bottom: 5px;
}

.send-msg-container {
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  display: inline-flex;
  margin: 5px 10px;

  .send-msg-input {
    width: 100%;
    border: 0;
    padding: 0 16px;
    outline: 0;
    color: #666b6b;
    transition: all, 0.2s ease-in-out;
    margin: 0;
  }

  .send-msg-icon {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    z-index: 1;
    padding-right: 5px;
    top: 0;
    right: 0;
    font-size: 1.15em;
    cursor: pointer;
    color: gray;
    &:hover {
      color: black;
    }
  }
}
