.philips-recall {
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  align-self: center;
  @media only screen and (max-width: 640px) {
    width: 100%;
    background: none;
    box-shadow: none;
    border-radius: 0px;
  }
}
.recall-screen-header {
  .recall-logo {
    width: 150px;
    height: 150px;
    align-self: center;
    justify-self: center;
    padding: 10px;
  }
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: #333;
  font-size: 1.25em;
  font-weight: bold;
  padding-top: 10px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 640px) {
  .reset-password-form {
    width: 400px;
    display: flex;
    background: none;
  }
}
.recall-screen {
  margin: 0 auto;
}

.philips-recall-container {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.philips-recall-title {
  text-align: center;
}
.philips-recall-text {
  font-size: 1em;
  color: red;
}
.philips-recall-form-container {
  text-align: left;
}
.recalled-products-container {
  margin: 5px 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.machine-selection-message {
  margin: 10px 0;
  color: red;
}

.philips-machine-card {
  width: 220px;
  padding: 8px;
  margin: 15px;
  text-align: center;
}

.machine-selected {
  background-color: #2196f3;
  border-radius: 0.5em;
}

.philips-machine-recall-current-machines-form {
  .philips-machine-container {
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0px 0px 7px #0000007a;
    justify-content: space-around;
    align-items: center;
    margin: 10px;
    min-height: 100px;
    column-gap: 5px;
    padding: 10px;
  }
}
