.company-logo-section {
  padding: 0.5rem 0 2rem 0;
  height: 50%;
  display: flex;
  align-items: center;
}

.company-logo {
  display: flex;
  max-height: 20rem;
  margin: 0 auto;
  max-width: 100%;
}

.card-container {
  box-shadow: -1px 1px 5px 2px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  padding: 1rem;
  margin: 0.5rem;
  width: 20rem;
  min-height: 15rem;
  justify-content: space-between;
}

.card-title {
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
}

.card-description {
  word-break: break-all;
}

.card-pdf-icon {
    width: 30px;
    height: 30px;
}

.carousel-item {
  display: flex;
  justify-content: center;
}

.tiles-section {
  height: 50%;
  padding: 2rem 4rem 4rem 4rem;
  background-color: #f3f3f3;
}

.carousel-wrapper {
  position: relative;
  max-width: 50rem;
  margin: 0 auto;
}

.react-multi-carousel-track {
  transition: transform 400ms ease-in-out 0s !important;
}

.carousel-container {
  position: unset !important;
  max-width: 50rem;
  margin: 0 auto;

  .react-multiple-carousel__arrow--left {
    left: calc(1% - 55px) !important;
  }

  .react-multiple-carousel__arrow--right {
    right: calc(1% - 55px) !important;
  }
}
.react-multi-carousel-dot-list {
  bottom: unset !important;
  margin-top: 1rem !important;
}
