@import "../../assets/styles/colors";

.login-screen {
  .login-screen-header {
    .login-logo {
      width: 150px;
      height: 150px;
      align-self: center;
      justify-self: center;
      padding: 20px;
    }
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    color: #333;
    font-size: 1.25em;
    font-weight: bold;
    padding-top: 10px;
    margin-bottom: 20px;
  }
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  .login-form {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    width: 400px;
    background: #fff;
    padding: 30px 50px;
    border-radius: 3px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    @media only screen and (max-width: 640px) {
      width: 80%;
      background: none;
      box-shadow: none;
      border-radius: 0px;
    }
    div { 
      width: 100%;
    }
  }
  .login-info {
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
  }
  .login-error {
    color: red;
    margin: 5px;
  }
  .login-message {
    color: green;
    margin: 5px;
  }
  .login-form-actions {
    display: flex;
    flex-direction: column;
    width: 350px;
    margin-top: 15px;
    button {
      margin-bottom: 15px;
    }
  }
  .login-info-text {
    padding: 15px 0px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .multifactor-form {
    padding: 0 2rem 2rem 2rem;
  }

  .multifactor-row-actions {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-top: 1rem;
    align-items: center;
  }

  .multifactor-column-actions {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 0.5rem;
  }

  .multifactor-methods {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .multifactor-method {
    margin: 0;
    padding: 0.5rem;
  }

  .multifactor-method .MuiRadio-root {
    display: none;
  }

  .multifactor-method .MuiFormControlLabel-label {
    display: flex;
    gap: 1rem;
  }

  .multifactor-method.selected {
    border: 2px solid rgb(23, 105, 170);
    border-radius: 4px;
  }
}
