.text-consent {
  $background: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .form {
    align-items: center;
    background: $background;
    color: #222;
    cursor: auto;
    display: flex;
    flex-direction: column;
    font-family: Open Sans, Helvetica, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    justify-content: center;
    line-height: 1.5;
    margin: 0;
    padding: 15px;
    width: 600px;

    @media only screen and (max-width: 640px) {
      width: 100%;
      justify-content: flex-start;
    }
  }

  .brand {
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    color: #eeeeee;
    font-size: 120%;
    margin-bottom: 1rem;
    margin-left: 15px;
    text-transform: uppercase;
    align-self: baseline;
  }
}
