$drawerWidth: 240px;

header.appBar {
  background: #fff;
  color: #000;

  .app-bar-title {
    display: flex;
    flex: 1;
    font-size: 1.25rem;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Poppins-Bold;
  }
  .app-bar-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  .notificationsAndNameContainer {
    display:flex;
    align-items: baseline;
    position: absolute;
    right: 0px;
    padding-right: inherit;
    max-width: 400px;
  }
  .notificationsIconContainer {
    position: relative;
    cursor: pointer;
    padding-right: 20px;
  }
  .usernameAndMenuContainer {
    display: flex;
    align-items: center;
  }
  .userName {
    cursor: pointer;
    font-family: Poppins-Regular
  }
  .newNotificationsAmountContainer {
    position: absolute; 
    border-radius: 20px; 
    width: 17px; 
    height: 17px; 
    font-size: 12px;
    color: #fff;
    background-color: #ff0000; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    left: 17px; 
    bottom: 23px;
    font-family: Poppins-Regular
  }
  
}

// @media (min-width: 600px) {
//   header.appBar {
//     width: calc(100% - 240px) !important;
//   }
//   // button.menuButton {
//   //   display: none;
//   // }
// }

button.menuButton {
  margin-right: 16px !important;
}
.page-content {
  display: flex;
  justify-content: center;
  flex: 1;
  max-height: calc(100vh - 65px);
  align-items: center;
}

