.notification-card {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  .notification-header {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .notification-content {
    display: grid;
    grid-template-columns: 1fr auto;
  }
  .notification-title {
    font-family: Poppins-Bold;
  }
  .notification-date {
    font-size: 12px;
    font-family: Poppins Regular;
    color: #ababab;
  }
  .notification-footer {
    margin-top: 10px;
    display: flex;
    flex: 1;
    align-items: center;
    .notification-timestamp,
    .notification-timespan {
      font-size: 12px;
      font-family: Poppins Regular;
      color: #333;
      display: flex;
      align-items: center;
      span {
        padding-left: 10px;
      }
    }
    .notification-timespan {
      margin-left: 25px;
    }
  }
  .notification-actions {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}
