.loading-spinner {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  border-width: 2px;
  border-style: solid;
  animation: spinner 0.75s 0s linear infinite;
  border-color: #24abfb;
  border-bottom-color: transparent;
  background: 0 0 !important;
  display: inline-block;
}
.loading-container {
  display: flex;
  justify-content: center;
  & > * + * {
    margin-left: 16px;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
    opacity: "1";
  }
  50% {
    transform: rotate(180deg);
    opacity: "0.9";
  }
  100% {
    transform: rotate(360deg);
    opacity: "1";
  }
}
