@media only screen and (max-width: 640px) {
  .video-container video {
    max-width: 100%;
    background: unset !important;
  }
}
.video-room {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-height: calc(100vh-85px);
  background: #333;
  color: white;
}

.video-container {
  position: relative;
  display: flex;
  flex: 1 1;
  width: 100%;
  .local.name {
    display: none;
  }
  .remote.name {
    position: absolute;
    top: 8px;
    left: 0;
    margin: 0;
    padding: 0.5em 0.5em 0.5em 2em;
    background: #2196f3;
    color: #fff;
    font-size: 16px;
    line-height: 1;
  }

  .remote.name::after {
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    top: 0;
    right: -2em;
    border: 1em solid #2196f3;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }

  .video-output {
    border: 1px solid #ccc;
  }
  &.local {
    .video-output {
      border: none;
      width: 150px;
      height: 150px;
    }
    width: 150px;
    height: 150px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 1;
  }
}
