.update-language {
  padding: 20px;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 640px) {
  .reset-password-form {
    width: 400px;
    display: flex;
    background: none;
  }
}
.update-language-title {
  text-align: center;
}
.update-language-text {
  color: red;
}
.update-language-container {
  text-align: center;
}
.update-language-container > div {
  margin-bottom: 20px;
}
.validating-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.validation-error {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  h3 {
    color: red;
  }
}
