@media only screen and (max-width: 640px) {
  .product-demo-container {
    flex-direction: column !important;
    display: flex !important;
    flex: 1 !important;
    padding: 0 !important;
    position: relative;
    padding: 0px !important;
  }
  .twilio-chat-container {
    max-height: 200px;
    background: none;
    .send-message-info {
      // display: none !important;
      // visibility: hidden !important;
    }
  }
}

.product-demo-container {
  display: grid;
  grid-template-columns: 1fr auto;
  border: 1px solid #333;
  flex: 1;
  .twilio-chat-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    .messages-container {
      background: #ddd;
    }
  }
  .send-message-info {
    text-align: center;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    background: #ddd;
    span {
      font-weight: normal;
    }
  }
}

.waiting-on-host {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
