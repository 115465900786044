.text-chat-container {
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  max-height: calc(100vh - 58px);

  .text-chat-window {
    display: flex;
    flex: 1;
    border: 1px solid #ccc;
    height: 100%;
    flex-direction: column;
  }
  .text-chat-connecting {
    div {
      padding: 5px;
    }
  }
}

@media only screen and (min-width: 640px) {
  .text-chat-container {
    padding: 20px;
    max-height: calc(100vh - 70px);
  }
  .text-chat-window {
    max-width: 500px;
  }
}
