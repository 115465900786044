.player-wrapper {
  background-color: black;
  position: relative;
  height: 75vh;
  width: 100%;
  overflow: hidden;
}

.css-csmqbv-MuiPaper-root-MuiDialog-paper {
  overflow: hidden !important;
}
