.sign-questionnaire {
  padding: 0px 10px;
  flex-direction: column;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  .questionnaire-form {
    display: flex;
    flex: 1;
    width: 60%;
    flex-direction: column;
    .question-container {
      margin: 10px;
    }
  }
}

@media only screen and (max-width: 640px) {
  .sign-questionnaire {
    .questionnaire-form {
      width: 100%;
    }
  }
}
