.signature-title {
  margin-bottom: 10px;
}

.date-sent-container {
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 5px;
  .signature-date-sent {
    margin-left: 5px;
  }
}
.signature-status {
  margin-top: 4px;
  font-family: Poppins-Regular;
  &.pending {
    color: #141c36b3;
  }
  &.signed {
    color: #53d1c3;
  }
}
.signature-action-btn {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
