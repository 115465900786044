.upcoming-chat-content {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  font-family: Poppins-Regular;
  padding: 0px 15px 15px 15px;
  color: #333;
  @media only screen and (max-width: 640px) {
    flex-direction: column;
  }
}

.upcoming-chat-date-container{
  font-family: Poppins-Bold;
  width: 100px;
  height: 75px;
  border-radius: 15px;
  float: left;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align : center;
}

.upcoming-chat-container {
  font-family: Poppins-Bold;
  display: flex;
  flex: 1;
  padding: 15px 15px 0px 15px;
  justify-content: space-between;
  .chat-header {
    font-size: 16px;
    color: #24abfb;
  }
  .chat-time {
    display:flex;
    align-items: center;
    font-size: 14px;
    font-family: Poppins-Regular;
    color: rgb(112,112,112);
    span {
      padding-left: 10px;
    }    
  }
}

.upcoming-chat-actions{
  display: flex;
  align-items: flex-end;
  vertical-align: bottom;
  float: left;
  padding-top: 107px;
}

.status-info{
  padding-top: 25px;
  font-size: 14px;
  font-family: Poppins-Bold;

  .status-info-confirmation-pending {
    color: rgb(255, 211, 0);
  }

  .status-info-cancelled {
    color: rgb(242, 21, 21);
  }
}


.chat-remaining-time {
  padding-top: 25px;
  color: #888;
  font-size: 14px;
  font-family: Poppins-Regular;
}

.rescheduleDisplay{
  .MuiDialog-container > div {
    max-width: 80% !important;
    width: calc(100% - 3em) !important;
  }
}