.form-field {
  width: auto;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    "inputLabel inputError"
    "inputField inputField";
  .form-field-header {
    display: inline-flex;
  }
  .form-field-header > * {
    margin-right: 0.75rem;
  }
  .form-field-error {
    grid-area: inputError;
    color: red;
  }
  .form-field-label {
    grid-area: inputLabel;
  }
  .form-field-label-append {
    color: red;
    position: relative;
    top: -0.25em;
    font-size: 95%;
  }
  .input-field {
    grid-area: inputField;
    &.has-error {
      border: 1px solid red;
    }
  }
  .input-field[type="checkbox"] {
    margin: 0;
    align-self: center;
  }
}
