.profile {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  .profile-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    .profile-panel-header {
      margin: 20px 0px;
      font-size: 1.25em;
      height: 30px;
    }
  }
  .profile-photo-container {
    display: flex;
    align-items: center;
    height: 300px;
    width: 300px;
    margin: 10px;
    justify-self: center;
    align-self: center;
    position: relative;
    .profile-photo {
      height: 270px;
      background: #ccc;
      width: 270px;
    }
    @media only screen and (max-width: 640px) {
      height: 250px;
      height: 250px;

      .profile-photo {
        width: 100%;
        height: 100%;
      }
    }
  }
  .fetching-profile-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
  .profile-content {
    padding-left: 25px;
  }
}
