.order-form-container {
  display: grid;
  grid-template-rows: 1fr auto;
  flex: 1;
}
.order-form {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}
.order-form-page {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.instructions {
  fill: #fff;
  font-size: 0.85rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-top: 8px;
  margin-bottom: 8px;
}
button.order-step-btn {
  margin-right: 8px;
}
.order-form-stepper {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  .stepper {
    flex: 1;
  }
  .stepper-actions {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
}

.order-form-prompt {
  color: #4281a6;
  margin-bottom: 5px;
  font-style: italic;
  font-size: 1rem;
  .supply-requested {
    font-weight: 700;
    color: #aa0000;
    margin: 0 2px;
  }
}

.order-form-warning {
  padding: 10px;
  border: 1px solid #ff3333;
  color: #ff3333;
  background: #ffcccc33;
  font-weight: 600;
  margin: 5px 0;
}

.order-form-info {
  padding: 10px;
  border: 1px solid #ccc;
  color: black;
  background: #dfdfdf;
  font-weight: 600;
  margin: 5px 0;
}

.choose-supplies {
  display: flex;
  flex-direction: column;
  flex: 1;
  .equipment-select {
    display: flex;
    flex-direction: column;
    > div {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  label {
    display: flex;
    align-items: center;
  }

  .supply-checkbox-container {
    padding-right: 15px;
  }

  input {
    margin: 0;
  }

  .description {
    color: #999;
  }

  .choose-supplies-button-group {
    display: flex;
    justify-content: space-around;

    button {
      width: 30%;
    }
  }
}

.order-summary {
  ul + ul {
    margin-top: 0.83rem;
  }

  li {
    border: 1px solid #d7d7d7;
    padding: 15px;
    font-size: 0.825em;
    display: flex;
    margin-bottom: 15px;
  }

  .order-summary-checkmark-container {
    height: 30px;
    width: 30px;
    display: flex;
  }

  .order-summary-item-description {
    margin-left: 15px;
  }
  .order-form-checkbox {
    font-size: 1rem;
    font-weight: 500;
    color: #2d2d2d;
  }
}

.additional-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}
.order-support {
  margin-top: 5px;
  margin-bottom: 5px;
  color: #888;
}

/* Phone Portrait and Landscape */
// @media only screen and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
//   .order-form-input {
//     display: inherit;
//     margin: 0;
//     padding: 0;
//   }
// }
